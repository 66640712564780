import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Search = {
  /**
   * Dispatched an action to perform a search
   *
   * @param {object} data data object
   */
  search(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.SEARCH,
    }, data);

    dispatch(obj);
  },
};

export default Search;
