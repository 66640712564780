import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Channel = {
  list() {
    dispatch({
      actionType: AppConstants.LIST_CHANNELS,
    });
  },
};

export default Channel;
