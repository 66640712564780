import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const DeliveryFormat = {
  list() {
    dispatch({
      actionType: AppConstants.LIST_DELIVERY_FORMATS,
    });
  },
};

export default DeliveryFormat;
